<template>
  <b10-base>
    <b10-toolbar
      :title="title"
      back
    />
    <b10-page-content>
      <v-sheet
        class="mx-3 mt-5"
      >
        <b10-date-picker
          v-model="ffijada"
          title="Fecha fijada"
          @change="changeFilter"
        />
      </v-sheet>
      <v-simple-table
        class="mx-3"
      >
        <template #default>
          <thead>
            <tr>
              <th class="text-left">
                Técnico
              </th>
              <th class="text-left">
                Fijados
              </th>
              <th class="text-left">
                Otros
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in planificacion"
              :key="index"
            >
              <td>{{ item.nombre_y_apellido }}</td>
              <td>
                <v-chip
                  label
                  dark
                  :color="item.cantidad_fijados === 0 ? colors.red.base : colors.green.base"
                  @click="clickFijados(item)"
                >
                  {{ item.cantidad_fijados }}
                </v-chip>
              </td>
              <td>
                <v-chip
                  label
                  dark
                  :color="item.cantidad_otros === 0 ? colors.red.base : colors.green.base"
                  @click="clickOtros(item)"
                >
                  {{ item.cantidad_otros }}
                </v-chip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <b10-fab-button
        left
        :icon="$vuetify.icons.values.prev"
        @click="clickSiguienteAnteriorDia(-1)"
      />
      <b10-fab-button
        :icon="$vuetify.icons.values.next"
        @click="clickSiguienteAnteriorDia(1)"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import { basePageMixin } from '@/mixins/basePageMixin'
import { sync } from 'vuex-pathify'
import { addDays, currentDate, toDate } from '@/utils/date'
import Data from './PlanificadorTecnicosData'
import colors from 'vuetify/lib/util/colors'

const pageStoreName = 'pagesPlanificadorTecnicos'

export default {
  mixins: [basePageMixin],
  beforeRouteLeave (to, from, next) {
    if (!this.beforeRouteLeaveBase(to, from, next)) {
      if (!this.rememberState) {
        this.ffijada = null
      }
      next()
    }
  },
  data () {
    return {
      pageStoreName,
      planificacion: [],
      colors,
    }
  },
  computed: {
    ffijada: sync(`${pageStoreName}/ffijada`),
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      if (this.ffijada) {
        this.ffijada = toDate(this.ffijada)
      } else {
        this.ffijada = currentDate()
      }
      this.title = `Planificador de técnicos ${this.$options.filters.shortDate(this.ffijada)}`
      const [dataset] = await Data.selectPlanificacion(this, this.ffijada)
      this.planificacion = dataset
    },
    async changeFilter () {
      await this.loadPage()
    },
    async clickSiguienteAnteriorDia (offset) {
      this.ffijada = addDays(this.ffijada, offset)
      await this.loadPage()
    },
    clickFijados (item) {
      if (item.cantidad_fijados > 0) {
        this.rememberState = true
        this.rememberScroll = true
        this.$appRouter.push({
          name: 'partes__parte-asignados-list',
          params: {
            _filter: {
              conFfijada: {
                value: true,
                options: { fixed: true },
              },
              ffijada: {
                value: this.ffijada,
                options: { fixed: true },
              },
              tecnico: {
                value: item.idtecnico,
                options: { fixed: true },
              },
            },
          },
        })
      }
    },
    clickOtros (item) {
      if (item.cantidad_otros > 0) {
        this.rememberState = true
        this.rememberScroll = true
        this.$appRouter.push({
          name: 'partes__parte-asignados-list',
          params: {
            _filter: {
              conFfijada: {
                value: false,
                options: { fixed: true },
              },
              tecnico: {
                value: item.idtecnico,
                options: { fixed: true },
              },
            },
          },
        })
      }
    },
  },
}
</script>
